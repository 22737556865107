<template>
    <div class="products">
        <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
                <li><router-link to="/">Dashboard</router-link></li>
                <li class="is-active"><a href="#" aria-current="page">Products</a></li>
            </ul>
        </nav>

        <div class="field is-grouped">
            <h1 class="title">Products</h1>

            <p class="control mt-1 ml-3">
                <router-link class="button is-primary is-small is-rounded p-3" to="product" :disabled="isLoading">
                    <span class="icon is-small"><font-awesome-icon icon="plus" /></span>
                </router-link>
            </p>
        </div>

        <Loading v-if="isLoading" />
        <div v-else class="table-container">
            <table class="table is-striped is-fullwidth has-top-border has-bottom-border">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Name</th>
                        <th>Description</th>
                        <th>Features</th>
                        <th></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="(product, index) in products" :key="'product' + index">
                        <td> {{ product.id }}</td>
                        <td>
                            <router-link :to="'/product/' + product.id">{{ product.name }}</router-link>
                        </td>
                        <td> {{ product.description }}</td>
                        <td> {{ product.features.length }}</td>
                        <td>
                            <button class="button is-white is-inverted is-small" @click="veryifyDeleteProduct(product)">
                                <font-awesome-icon :icon="['fa', 'trash-alt']" />
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import { secureGet, secureDelete } from '../secureFetch.js';

    import Loading from '../components/Loading';

    export default {
        name: 'Products',
        components: {
            Loading
        },
        computed: {
            hostUrl: function() {
                return this.$store.state.hostUrl;
            }
        },
        data() {
            return {
                isLoading: true,
                products: null,
                sortBy: 1,
                sortDirection: 1
            }
        },
        mounted() {
            this.getProducts();
        },
        methods: {
            getProducts() {
                this.isLoading = true;

                secureGet(this.hostUrl + "v1/product/all-products")
                    .then(data => {
                        this.isLoading = false;

                        if (data && data.success) {
                            this.products = data.products;
                        }
                    });
            },
            veryifyDeleteProduct(product) {
                if (confirm("Really delete " + product.name + "?")) {
                    secureDelete(this.hostUrl + "v1/product/" + product.id)
                        .then(data => {
                            this.isLoading = false;

                            if (data && data.success) {
                                this.getProducts();
                            }
                        });
                }
            }
        }
    };
</script>

<style scoped>
</style>

